import React, { Suspense } from "react";
import AOS from "aos";
import styled from "styled-components";
import "aos/dist/aos.css";
import Image from "./Assets/connectcrew.png";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const IndexPage = React.lazy(() => import("./pages/index"));
const About = React.lazy(() => import("./pages/AboutUs"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Wedding = React.lazy(() =>  import("./pages/Wedding"));
const AutoExpo = React.lazy(() => import("./pages/AutoExpo"));
const Services = React.lazy(() => import("./pages/Services"));
const SupportServices = React.lazy(() => import("./pages/SupportServices"));
const CreateYourOwnEvents = React.lazy(() =>
  import("./pages/CreateYourOwnEvent")
);

const Container = styled.div`
  height: 90vh;
  width: 100%;
  text-align: center;
  img {
    height: 300px;
    width: 300px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    margin: auto;
    object-fit: contain;
  }
`;

const Loading = () => {
  return (
    <Container>
      <img src={Image} />
    </Container>
  );
};

const App = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Router>
          <Switch>
            <Route exact component={IndexPage} path="/" />
            <Route exact component={About} path="/about" />
            <Route exact component={Contact} path="/contact" />
            <Route exact component={Contact} path="/contact/:id" />
            <Route exact component={Wedding} path="/wedding" />
            <Route exact component={AutoExpo} path="/autoexpo" />
            <Route exact component={Services} path="/services" />
            <Route exact component={Services} path="/services/:id" />
            <Route exact component={SupportServices} path="/supportservices" />
            <Route
              exact
              component={SupportServices}
              path="/supportservices/:id"
            />
            <Route
              exact
              component={CreateYourOwnEvents}
              path="/createyourownevents"
            />
          </Switch>
        </Router>
      </Suspense>
    </>
  );
};
export default App;
